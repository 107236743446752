.home-page__card {
  margin-bottom: 1.5rem; }

.home-page__checkout-reminder {
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 0.5em; }

  select, button, .select {
    width: 100%; } }
