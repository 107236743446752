.login-page {
  background-color: $light; }

.login-page__hero-body {
  justify-content: center; }

.login-page__card {
  padding: 2rem; }

.login-page__submit {
  text-align: right;
 }  // margin-top: 1.5em

.login-page__submit-control {
  text-align: right; }

.login-page__help {
  padding-bottom: 0.5em; }
