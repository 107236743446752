.menu-bar {
  position: relative;

  &::after {
    position: absolute;
    content: '';
    left: 1rem;
    right: 1rem;
    bottom: 0;
    height: 2px;
    background-color: #808080; } }
