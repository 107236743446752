.number-input {
  min-width: 10rem;
  max-width: 100%;

  &.number-input--small {
    min-width: 5.5rem;
    width: 6.5rem; } }

.number-input__input {
  font-weight: bold;
  text-align: right; }

.number-input__middle-control {
  flex-grow: 1; }
