.edit-event-page__status-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap; }

.edit-event-page__status-separator {
  margin: 0 0.5em; }

.edit-event-page__status-button {
  margin: 0.5em 0; }

.edit-event-page__action-row {
  justify-content: flex-start;

  .column {
    flex-grow: 0; } }
