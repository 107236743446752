.printer {
  display: none; }

.printer__iframe {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  opacity: 0; }

// .printer__content
//   position: fixed
//   top: 0
//   left: 0
//   width: 100vw
//   overflow: hidden
//// border: 1px solid blue

.printer__content-inner {
  width: 468pt; // 6.5 inches
  // font-size: 12pt
  margin: auto;
 }  // border: 1px solid red

@media print {
  html, body {
    overflow: visible; } }
