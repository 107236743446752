.events-calendar {
  position: relative; }

.events-calendar__weeks {
  margin: 1em 0; }

.events-calendar__week {
  display: flex;
  justify-content: center; }

.events-calendar__day {
  width: 6em;
  height: 6em;
  margin: 0.2em;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;

  @include mobile {
    width: 2.5em;
    height: 2.5em;
    align-items: center; } }

.events-calendar__day-number {
  font-size: 1.5em;
  font-weight: bold;
  line-height: 1.3; }

.events-calendar__day-placeholder {
  flex: 1 1 100%; }

.events-calendar__day-name {
  font-size: 0.75em;
  line-height: 1;

  @include mobile {
    display: none; } }

.events-calendar__day-events {
  @include mobile {
    display: none; } }

.events-calendar__overlay {
  position: absolute;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: $scheme-main;
  visibility: hidden;

  &.events-calendar__overlay--enabled {
    visibility: visible; } }
