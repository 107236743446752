.filtered-events-list__event {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 0.5em; }

  > div:not(:last-child) {
    margin-right: 0.5em; } }

.filtered-events-list__event-square {
  height: 3.5em;
  width: 3.5em;

  border: 2px solid $grey;
  box-sizing: border-box;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.filtered-events-list__event-day {
  font-weight: bold;
  font-size: 1.5em;

  line-height: 1.1; }

.filtered-events-list__event-month {
  line-height: 1.1; }

.filtered-events-list__event-name {
  line-height: 1.5;
  font-size: 1em;
  border: 1px solid $border;
  padding: calc(0.5em - 1px) 1em;
  flex-grow: 1;
  text-align: center;
  font-weight: bold;
  border-radius: 4px; }

.filtered-events-list__event-status-tag {
  width: 12em; }
