.reports-page__loader {
  display: flex;
  justify-content: center;
  align-items: center; }

.reports-page__button {
  &:not(:last-child) {
    margin-right: 0.5em; } }

.reports-page__print {
  td, th {
    font-size: 1rem; } }

.reports-page__print-header {
  margin-bottom: 1rem; }

.reports-page__print-logo {
  height: 8em;
  margin-bottom: 0.5em; }
