.kitchen-page__button {
  margin-left: .5rem;
  padding: 0 2rem;
  height: 5rem;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1; }

.kitchen-page__placeholder {
  text-align: center; }

.kitchen-page__small {
  font-size: 0.75em; }

.kitchen-page__tag {
  &:not(:last-child) {
    margin-right: 0.5em; } }

.kitchen-page__tag-icon {
  margin-right: 0.5em; }

.kitchen-page--blinking {
  animation: kitchen-page-blink 1.2s step-end infinite; }

@keyframes kitchen-page-blink {
  0% {
    background-color: $danger; }
  15% {
    background-color: transparent; }
  20% {
    background-color: $danger; }
  30% {
    background-color: transparent; } }
