.orders-page__row {
  @include no-touchie;
  cursor: pointer;

  &.orders-page__row-active {
    font-weight: bold;
    background-color: $success;
    color: $success-invert;

    &:hover {
      color: $text; } }

  &.orders-page__row-unpaid {
    color: mix($text, $light, $weight: 25%); } }

.orders-page__order-modal {
  .table {
    width: 100%; } }
