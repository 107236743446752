.orders-report__price-field {
  min-width: 6.2em;
  max-width: 6.2em;
  width: 6.2em; }

.orders-report__total-row {
  font-weight: bold; }

.orders-report__spacer-row {
  &, td {
    height: 0.25em;
    padding: 0; } }
