.order__total {
  font-size: 1.25em; }

.order__total-wrapper {
  align-self: flex-start;
  align-items: flex-start; }

.order__header {
  cursor: pointer; }

.order--collapsed {
  .order__body {
    max-height: 0;
    padding: 0;
    overflow: hidden; }

  .order__header {
    border-radius: 4px; } }

.order__square {
  width: 1.5em;
  height: 1.5em;
  border: 2px solid $text-light;
  border-radius: 4px;
  margin: 0 .5em;
  display: inline-block; }

.order__square-column {
  width: 6em; }

@media print {
  .order__event-level {
    flex-direction: column-reverse; }

  .order__event-list {
    margon-top: 1em; }

  .order__total-wrapper {
    align-self: flex-end; } }

.order__mark-buttons {
  flex-direction: column; }

.order__mark-button-row {
  margin: .5em;

  & + & {
    margin-top: 0; } }

.order__kitchen-worksheet {
  margin-bottom: 2em; }

.order__event-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }

.order__special-request {
  margin-bottom: 1em;
  width: 100%;

  &.order__special-request--print {
    margin-top: 1em;
    // max-width: 20rem

    & .order__special-request-text {
      font-size: 1.6em;
      border-color: $text; } } }

.order__special-request-title {
  &, &:not(:last-child) {
    margin-bottom: 0; } }

.order__special-request-text {
  white-space: pre-wrap;
  font-size: 1em;
  line-height: 1.1;
  margin-top: 0.5em;
  padding: 1em;
  border: 1px solid $grey-lighter;
  border-radius: 0.5em;
  background-color: $white;
  color: $text;
  max-width: 48rem; }

.order__print {
  .subtitle, td, th {
    font-size: 1rem; } }

.order__header-icon {
  margin-right: 0.5em; }

.order__print-logo {
  height: 8em;
  margin-bottom: 0.5em; }

.order__print-address {
  margin-bottom: 1em;
  font-size: 1.25em; }

.order__print-address-thank-you-line {
  margin-top: 0.75em; }

.order__print-oversized-column {
  display: flex;
  justify-content: flex-end; }

.order__print-oversized-box {
  padding: 1rem;
  border: 1px solid black;
  border-radius: 1rem;
  text-align: center; }

.order__print-oversized {
  font-size: 2.9rem;
  font-weight: bold;
  line-height: 1.15; }

.order__print-slightly-less-oversized {
  font-size: 1.6rem;
  font-weight: bold; }
