$input-color: hsl(0, 0%, 21%);
$SM-fieldHeight: 36px;
$SM-borderRadius: 4px;
$SM-textColor: $grey-darker;
$SM-borderColor: $grey-lighter;
$SM-mutedColor: rgba($input-color, .2);
$SM-valuePlaceholderColor: rgba($input-color, .2);
$SM-dropDownZIndex: 9;
$SM-dropDownHeight: 410px;

.control {
  .MultiSelect {
    box-sizing: border-box;

    .Header {
      box-shadow: inset 0 1px 2px rgba($black, .1);
      box-sizing: border-box;
      padding: 0 calc(.625em - 1px); }

    .OptionList {
      max-height: 8em; } }

  &.is-danger {
    .MultiSelect {
      .Header {
        border: 1px solid $red; } } } }

