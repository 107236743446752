.events-list__title {
  &, &:not(:last-child) {
    margin-bottom: 0.5em; } }

.events-list__tags {
  margin: 0.5em 0; }

.events-list__preview {
  margin: 0.5em 0;
  white-space: pre-wrap;
  font-weight: bold; }

.events-list__buttons {
  text-align: right; }

.events-list__placeholder {
  text-align: center; }

.events-list__add-button {
  text-align: center;
  margin-top: 1em; }
