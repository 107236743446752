.catering-order__header {
  cursor: pointer; }

.catering-order--collapsed {
  .catering-order__body {
    max-height: 0;
    padding: 0;
    overflow: hidden; }

  .catering-order__header {
    border-radius: 4px; } }

.catering-order__row {
  display: flex;

  flex-direction: column;
  @media (min-width: $tablet) {
    flex-direction: row; }

  &:not(:last-child) {
    margin-bottom: 0.5em; } }

.catering-order__field {
  flex: 1 1 10px;

  &:not(:last-child) {
    margin-bottom: 0.5em;

    @media (min-width: $tablet) {
      margin-bottom: 0;
      margin-right: 1em; } } }

.catering-order__field-title {
  font-size: 0.9em;
  font-weight: bold;
  line-height: 1.1; }

.catering-order__field-text {
  font-size: 1em; }

.catering-order__field-text--comment {
  white-space: pre-wrap;
  font-size: 1em;
  line-height: 1.1;
  margin-top: 0.5em;
  padding: 0.5em;
  border: 1px solid $grey-lighter;
  border-radius: 0.5em; }

// .catering-order__field--double
//   flex: 2 2 20px
//   padding-right: 1em

.catering-order__field-table {
  margin-top: 0.5em;
  // overflow-x: scroll
 }  // max-width: calc(100vw - 6em)

.catering-order__lunchbox-items {
  margin-left: 1em;
  font-size: 0.85em; }

.catering-order__total-row {
  font-weight: bold; }

.catering-order__row--buttons {
  justify-content: space-between; }

.catering-order__field--buttons {
  display: flex;
  flex-direction: row;
  align-items: center;

  flex-basis: auto;
  flex-grow: 0; }

.catering-order__status-separator {
  margin: 0 0.5em; }

.catering-order__status-button {
  margin: 0.5em 0; }

.catering-order__button {
  &:not(:last-child) {
    margin-right: 0.5em; } }

.catering-order__print {
  .subtitle, td, th {
    font-size: 1rem; } }

.catering-order__header-icon {
  margin-right: 0.5em; }

.catering-order__print-logo {
  height: 8em;
  margin-bottom: 0.5em; }

.catering-order__print-address {
  margin-bottom: 1em;
  font-size: 1.25em; }

.catering-order__print-address-thank-you-line {
  margin-top: 0.75em; }

.catering-order__square {
  width: 1.5em;
  height: 1.5em;
  border: 2px solid $text-light;
  border-radius: 4px;
  margin: 0 .5em;
  display: inline-block; }

.catering-order__square-column {
  width: 6em; }
