.user-row__cell {
  width: 20%;

  &.user-row__cell--actions {
    width: 40%; } }

.user-row__password-prompt {
  position: relative; }

.user-row__password-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;

  visibility: hidden;

  &.user-row__password-loading--enabled {
    visibility: visible; } }
