.edit-food-page__notice {
  color: $grey-light;
  text-align: center;
 }  // margin: -0.5em 0

.edit-food-page__image-card {
  width: calc(min(100vw - 3em, 600px));

  // @media (min-width: $tablet)
  margin-left: auto;
  margin-right: auto; }

.edit-food-page__image-card-content {
  font-size: calc(min(2.8vw, 1em));
  padding: 1em;

  .level {
    margin-bottom: 1em; }

  button {
    font-size: 1em; } }

.edit-food-page__configurator {
  overflow-x: scroll;
  max-width: calc(100vw - 3em);
  padding-bottom: 5em; }

.edit-food-page__summary-field-value {
  white-space: pre-wrap; }
